export enum MFO_STEP {
  OFFER = 'offer',
  PAYMENT_DETAILS_WAITING = 'payment_details_waiting',
  PAYMENT_DETAILS = 'payment_details',
  PAYMENT_DETAILS_BANK_SELECTION = 'payment_details_bank_selection',
  PAYMENT_DETAILS_ERROR = 'payment_details_error',

  DOCUMENTS = 'documents',
  DOCUMENTS_DECLINED = 'documents_declined',

  CONTRACT_TERMS_WAITING = 'contract_terms_waiting',
  CONTRACT_TERMS = 'contract_terms',
  SIGNATURE = 'signature',
}
