import React from 'react';

import { Card, Divider, Icon, Skeleton, Space, Typography } from '@sravni/react-design-system';
import { Deposit, House } from '@sravni/react-icons';
import { ArrowRight } from '@sravni/react-icons';
import { useStyles } from '@sravni/react-utils';

import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';
import { PROPOSITION_TYPE } from '../../constants/proposition';

import styles from './PropositionCard.module.scss';
import { PropositionCardProps, IPropositionTypes } from './types/propositionCard';

const { Heading, Text, Link } = Typography;

const getPropositionIcon = (type: IPropositionTypes): React.ReactElement => {
  switch (type) {
    case PROPOSITION_TYPE.DEPOSITS:
      return <Deposit />;
    case PROPOSITION_TYPE.MORTGAGE:
      return <House />;
  }
};

export const PropositionCard: React.FC<PropositionCardProps> = (props) => {
  const { type, title, subTitle, link, data, ...restProps } = props;
  const cx = useStyles(styles);

  const handlePropositionLinkClick = React.useCallback(() => {
    pushEvent(
      makeMainGEvent({
        eventCategory: type,
        eventAction: link.eventAction ?? 'Переход из плиток с деталями',
        eventLabel: link.eventLabel ?? link.title,
      }),
    );
  }, [type, link.eventAction, link.eventLabel, link.title]);

  const handleDataClick = React.useCallback(
    (propositionTitle?: string, rateTitle?: string) => {
      const eventLabel = `Тип - ${propositionTitle}|Ставка - ${rateTitle}`;

      pushEvent(
        makeMainGEvent({
          eventCategory: type,
          eventAction: 'Переход из плиток с деталями',
          eventLabel,
        }),
      );
    },
    [type],
  );

  return (
    <Space direction="vertical" justify="space-between" {...restProps}>
      <Space size={16} align="center">
        <Icon background="light" color="green" shape="round">
          {getPropositionIcon(type)}
        </Icon>
        <Space size={4} direction="vertical">
          <Heading level={4}>{title}</Heading>
          <Text size={14} className="h-color-D60">
            {subTitle}
          </Text>
        </Space>
      </Space>
      <Card className={cx('h-mt-16', 'propositionCard')} size={16}>
        <Space direction="vertical">
          <Space size={[32, 0]} className={cx('propositionsWrapper')} direction="vertical">
            {Array.isArray(data) &&
              data.map(({ title: propositionTitle, rateTitle, href }) => (
                <React.Fragment key={propositionTitle}>
                  <Link
                    href={href}
                    className={cx('propositionLink')}
                    onClick={() => handleDataClick(propositionTitle, rateTitle)}
                  >
                    <Space align="center" justify="space-between">
                      <Text nowrap strong className={cx('propositionPeriod')}>
                        {propositionTitle}
                      </Text>
                      {rateTitle ? (
                        <Space justify="start" className={cx('propositionRateWrapper')}>
                          <Text className={cx('propositionRate')} strong nowrap>
                            {rateTitle}
                          </Text>
                        </Space>
                      ) : (
                        <Skeleton.Paragraph className={cx('rateSkeleton')} />
                      )}
                      <Icon className={cx('arrowIcon')} shape="round" background="light">
                        <ArrowRight />
                      </Icon>
                    </Space>
                  </Link>
                  <Divider className={cx('divider')} />
                </React.Fragment>
              ))}
          </Space>

          <Link
            href={link.href}
            className={cx('propositionsLink')}
            onClick={handlePropositionLinkClick}
            rel={link.isExternalLink ? 'noreferrer nofollow' : undefined}
          >
            <Space align="center" justify="space-between">
              <Text strong nowrap>
                {link.title}
              </Text>
              <Icon className={cx('arrowIcon')} shape="round" background="light">
                <ArrowRight />
              </Icon>
            </Space>
          </Link>
        </Space>
      </Card>
    </Space>
  );
};
