import { useDepositsTop } from '../../../../hooks/deposits';
import { DepositsTop } from '../../../../interfaces/deposits';
import { makePeriodName } from '../../../../utils/makePeriodName';
import { PROPOSITION_LINK, PROPOSITION_TYPE } from '../../constants/proposition';
import { IPropositions } from '../../types/propositionBlock';
import { PropositionCard } from '../PropositionCard';

import styles from './DepositsCard.module.scss';

/** Helper function prepares data for the Card */
const prepareDepositsTopData = (depositsTopData: DepositsTop): IPropositions => {
  return depositsTopData.map(({ period, rate, depositHref }) => {
    return { title: makePeriodName(period), rateTitle: rate ? `${rate}%` : null, href: depositHref };
  });
};

export const DepositsCard = () => {
  const depositsTop = useDepositsTop();

  return (
    <PropositionCard
      type={PROPOSITION_TYPE.DEPOSITS}
      title="Выгодные вклады"
      subTitle="Проверили ставки в 256 банках"
      link={PROPOSITION_LINK.DEPOSITS}
      data={prepareDepositsTopData(depositsTop)}
      className={styles.deposits}
    />
  );
};
