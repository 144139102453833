import { NextPage } from 'next';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ThemeName } from '@sravni/design-system-theme';
import { Grid, Layout } from '@sravni/react-design-system';
import { PreFooter } from '@sravni/react-pre-footer';
import { useStyles, useIsMobile } from '@sravni/react-utils';

import { Currencies } from '../../components/Currencies';
import { LayoutWrapper } from '../../components/LayoutWrapper';
import Materials from '../../components/Materials';
import { ProductBlock } from '../../components/ProductBlock';
import { PropositionBlock } from '../../components/PropositionsBlock';
import { SecurityBlock } from '../../components/SecurityBlock';
import { SpecialProjects } from '../../components/SpecialProjects';
import { Travel } from '../../components/Travel';
import { useMetadata } from '../../hooks/metadata';
import { fetchBanners } from '../../store/reducers/advertising';
import styles from '../../styles/common.module.scss';

interface IIndexPageProps {
  theme: ThemeName;
}

const IndexPage: NextPage<IIndexPageProps> = () => {
  const { prefooter } = useMetadata();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const cx = useStyles(styles);

  // TODO нужно для а/б теста
  // const abTestingSdk = useAbTestingSdk();
  // const isBtest = abTestingSdk.checkExperimentVariant('45b0dac3-ad00-4e0a', '1');

  useEffect(() => {
    dispatch(
      fetchBanners({
        params: {
          BannerTypes: ['top', 'teaserInPropositions'],
          Page: 'home',
        },
      }),
    );
  }, [dispatch]);

  return (
    <>
      {/*TODO: пока остается. Есть намерение вернуть кредитный рейтинг в том же виде, но в другое место. Обсуждено с Антоном Смирновым*/}
      {/*{isBtest && isMobile && user && <CreditScoring />}*/}
      <ProductBlock />
      {/* TODO вероятно надо выпилить совсем (@anton smirnov) */}
      {/* <div className={cx('universal-wrapper', { 'universal-wrapper--last-deal': !!user })}>{user && <LastDeal />}</div> */}
      {
        <>
          <LayoutWrapper topBorders={false} bottomBorders={false} whiteBackground>
            <Layout
              className={cx('inviteWrapper', {
                'h-pt-32': !isMobile,
                'h-pt-24': isMobile,
              })}
            >
              <Grid.Row
                className={cx('')}
                justify={'space-between'}
                gutter={[32, isMobile ? 16 : 0]}
                wrap={isMobile}
                align={'middle'}
              >
                <Grid.Col span={isMobile ? 12 : 6}>
                  {/* <DownloadApp /> */}
                  <Travel />
                </Grid.Col>
                <Grid.Col span={isMobile ? 12 : 6}>
                  <Currencies />
                </Grid.Col>
              </Grid.Row>
            </Layout>
          </LayoutWrapper>
          <PropositionBlock />
        </>
      }
      {/* {isBtest && (
        <>
          <AddonsBlock />
          {user?.sub && <RecentActions />}
          <Monitoring />
        </>
      )} */}
      <Materials />
      <SpecialProjects />
      <LayoutWrapper topBorders={false} bottomBorders={false} whiteBackground>
        <Layout className="h-pt-40">
          <SecurityBlock />
        </Layout>
      </LayoutWrapper>
      <PreFooter columns={prefooter} />
    </>
  );
};

export default IndexPage;
