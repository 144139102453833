import React from 'react';

import { useIsMobile } from '@sravni/react-utils';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './LoansCard.module.scss';

export const LoansCard = () => {
  const isMobile = useIsMobile();

  const item: IProductData = React.useMemo(
    () => ({
      key: TYPES.LOANS,
      title: 'Займы',
      eventCategory: 'Микрокредиты',
      eventAction: 'Переход из плиток',
      subtitle: '',
      pageUrl: isMobile ? '/zaimy/full-deal-offer/' : '/zaimy/',
      picture: [
        <source
          key={`source-product-${TYPES.MORTGAGE_INSURANCE}-webp`}
          srcSet={require('./img.png')}
          media={`(min-width: ${styles.breakpointTablet})`}
          type="image/webp"
        />,
        <img key={`img-product-${TYPES.MORTGAGE_INSURANCE}`} src={require('./img_mob.png')} alt="credits rating" />,
      ],
    }),
    [isMobile],
  );

  return <LinkCard item={item} className={styles.loans} />;
};
