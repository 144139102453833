import { ACTION_TYPE, PRODUCT_TYPE, ProductActionType } from '../../../interfaces/recentAction';
import { MORTGAGE_INSURANCE_TYPE } from '../types/actionDataTypes';

export const recentActionEventCategory = 'Брошенные расчеты и заявки';
export const recentActionEventClick = 'Клик по продукту';
export const recentActionEventAction = 'Открытие попапа-Показ баннера возврата';

export const mortgageInsuranceTypeMap = {
  [MORTGAGE_INSURANCE_TYPE.LIFE]: 'Жизнь',
  [MORTGAGE_INSURANCE_TYPE['PROPERTY-LIFE']]: 'Жизнь + Квартира',
  [MORTGAGE_INSURANCE_TYPE.PROPERTY]: 'Квартира',
};

export const eventLabelMap: Partial<Record<ProductActionType, string>> = {
  [`${PRODUCT_TYPE.OSAGO}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'ОСАГО|Продолжить рассчет',
  [`${PRODUCT_TYPE.OSAGO}-${[ACTION_TYPE.ORDER_V2]}`]: 'ОСАГО|Продлить полис',
  [`${PRODUCT_TYPE.MORTGAGE}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Страхование ипотеки|Продолжить расчёт',
  [`${PRODUCT_TYPE.MORTGAGE}-${[ACTION_TYPE.ORDER_V2]}`]: 'Страхование ипотеки|Продлить полис',
  [`${PRODUCT_TYPE.CREDIT}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'CREDIT|Этап воронки - Продолжить оформление',
  [`${PRODUCT_TYPE.CREDIT}-${[ACTION_TYPE.ORDER_V2]}`]: 'CREDIT|Этап воронки - Продолжить оформление',
  [`${PRODUCT_TYPE.VZR}-${[ACTION_TYPE.SEARCH_RESULTS_V2]}`]: 'VZR',
  [`${PRODUCT_TYPE.MFO}-${[ACTION_TYPE.DRAFT]}`]: 'MFO|Заполнение анкеты',
  [`${PRODUCT_TYPE.MFO}-${[ACTION_TYPE.DEAL]}`]: 'MFO|Подбор оффера',
  [`${PRODUCT_TYPE.MFO}-${[ACTION_TYPE.OFFER]}`]: 'MFO|Привязка карты',
  [`${PRODUCT_TYPE.MFO}-${[ACTION_TYPE.DOCUMENTS]}`]: 'MFO|Загрузка документов',
  [`${PRODUCT_TYPE.MFO}-${[ACTION_TYPE.SIGNATURE]}`]: 'MFO|Подписание',
};
