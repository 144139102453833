import pluralize from '@sravni/utils/lib/pluralize';

import { ACTION_TYPE, IUserAction, PRODUCT_TYPE } from '../../../interfaces/recentAction';
import { CREDIT_ACTION_EXTRNAL_STATUS, CREDIT_ACTION_STATUS } from '../types/actionDataTypes';
import { IRecentActions } from '../types/recentActionCard';

import { getCreditCalculation } from './getCreditData';
import { getMfoDeal, getMfoDraft } from './getMfoData';
import { getMortgageOrder, getsMortgageCalculation } from './getMortgageData';
import { getOsagoCalculation, getOsagoOrder } from './getOsagoData';
import { getPrimaryAction } from './getPrimaryAction';
import { getVZRData } from './getVZRData';
import {
  isMortgageCalculation,
  isMortgageOrder,
  isOsagoCalculation,
  isRelevantDate,
  isOsagoOrder,
  isCreditCalculation,
  isCreditOrder,
  isVZRSearch,
  isMfoDraft,
  isMfoDeal,
} from './validateData';

/** Helper function prepares recent action cards data */
export const prepareActionCards = (actions: IUserAction[]): IRecentActions => {
  const creditSelectionData = {
    index: null,
    products: null,
    approveCount: 0,
  };

  const data = actions.reduce<IRecentActions>(
    (acc, action) => {
      switch (action.productType) {
        case PRODUCT_TYPE.MORTGAGE:
          if (isMortgageCalculation(action)) {
            // Расчет
            acc.calculations.push(getsMortgageCalculation(action));
            return acc;
          }
          // Полис
          if (isMortgageOrder(action) && isRelevantDate(action) && !action.payload.nextPolicyNumber) {
            acc.orders.push(getMortgageOrder(action));
            return acc;
          }

          break;

        case PRODUCT_TYPE.OSAGO:
          // Расчет
          if (isOsagoCalculation(action)) {
            acc.calculations.push(getOsagoCalculation(action));
            return acc;
          }
          // Полис
          if (isOsagoOrder(action) && isRelevantDate(action) && !action.payload.nextPolicyNumber) {
            acc.orders.push(getOsagoOrder(action));
            return acc;
          }
          break;

        case PRODUCT_TYPE.CREDIT:
          // Расчет
          if (isCreditCalculation(action)) {
            const { payload } = action;
            const products = payload.products?.length;
            creditSelectionData.products = products;
            creditSelectionData.index = acc.calculations.length;

            acc.calculations.push(getCreditCalculation(action));
            return acc;
          }

          // Заявка
          if (isCreditOrder(action)) {
            const { payload } = action;
            if (
              payload.apiStatus === CREDIT_ACTION_STATUS.ACCEPTED &&
              [
                CREDIT_ACTION_EXTRNAL_STATUS.APPROVED,
                CREDIT_ACTION_EXTRNAL_STATUS.FINAL_APPROVED,
                CREDIT_ACTION_EXTRNAL_STATUS.CONFIRMED,
              ].includes(payload.externalRequestStatus)
            ) {
              creditSelectionData.approveCount = creditSelectionData.approveCount + 1;
            }
          }

          break;

        case PRODUCT_TYPE.VZR:
          // Расчет
          if (isVZRSearch(action)) {
            acc.calculations.push(getVZRData(action));
            return acc;
          }

          break;

        case PRODUCT_TYPE.MFO:
          // Расчет
          if (isMfoDraft(action)) {
            acc.calculations.push(getMfoDraft(action));
            return acc;
          }

          if (isMfoDeal(action)) {
            acc.calculations.push(getMfoDeal(action));
            return acc;
          }

          break;
      }

      return acc;
    },
    { orders: [], calculations: [], primaryAction: {} },
  );

  if (creditSelectionData.index) {
    const { index, products, approveCount } = creditSelectionData;
    const hintString = [
      products ? `${products} ${pluralize(products, 'предложение', 'предложения', 'предложений')}` : '',
      approveCount ? `, ${approveCount} одобрено` : '',
    ].join('');
    const hint = hintString ?? 'Продолжить заполнение';

    data.calculations[index].hint = hint;
    data.calculations[index].product = `${PRODUCT_TYPE.CREDIT}-${ACTION_TYPE.ORDER_V2}`;
  }

  data.primaryAction = getPrimaryAction(data);

  return data;
};
