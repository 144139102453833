import React, { useEffect } from 'react';

import { Dialog, Sheet, Space, Typography } from '@sravni/react-design-system';
import { useStyles } from '@sravni/react-utils';
import { useBoolean } from '@sravni/react-utils';

import { deleteRecentUserAction } from '../../../../services/recentAction';
import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';
import { eventLabelMap, recentActionEventCategory, recentActionEventClick } from '../../constants';
import { IRecentActions } from '../../types/recentActionCard';
import { ActionCard } from '../ActionCard/ActionCard';

import styles from './ActionCardsContainer.module.scss';

const { Heading } = Typography;

interface ActionCardsContainerProps {
  recentActions?: IRecentActions;
  isMobile?: boolean;
  onDelete?: () => void;
}

export const ActionCardsContainer: React.FC<ActionCardsContainerProps> = ({ recentActions, isMobile, onDelete }) => {
  const { orders, calculations, primaryAction } = recentActions;
  const cx = useStyles(styles);
  const [visible, setVisible] = useBoolean(false);

  const withOrders = !!orders.length;
  const withCalculations = !!calculations.length;
  const isSingleCard = orders.length + calculations.length <= 1;

  const title = withOrders ? 'У вас есть полисы к продлению' : 'Ваши расчёты и заявки';

  const products = React.useMemo(
    () => [...orders, ...calculations].map((action) => eventLabelMap[action.product]).join(', '),
    [orders, calculations],
  );

  const handleClick = () => {
    if (!isSingleCard) {
      setVisible.on();
      pushEvent(
        makeMainGEvent({
          eventAction: `Открытие попапа`,
          eventCategory: recentActionEventCategory,
          eventLabel: products,
        }),
      );
    } else {
      primaryAction.product &&
        pushEvent(
          makeMainGEvent({
            eventAction: recentActionEventClick,
            eventCategory: recentActionEventCategory,
            eventLabel: eventLabelMap[primaryAction.product],
          }),
        );
    }
  };

  const handleClose = React.useCallback(
    async (actionId: string) => {
      await deleteRecentUserAction(actionId);
      onDelete?.();
    },
    [onDelete],
  );

  useEffect(() => {
    if (!isSingleCard) {
      !visible &&
        pushEvent(
          makeMainGEvent({
            eventAction: `Показ баннера возврата`,
            eventCategory: recentActionEventCategory,
            eventLabel: products,
          }),
        );
    } else {
      visible && setVisible.off();
    }
  }, [products, visible, setVisible, isSingleCard]);

  const cards = React.useMemo(() => {
    return (
      <>
        {withOrders && (
          <>
            <Space size={16} direction="vertical">
              {orders.map(({ ...rest }, i) => (
                <ActionCard key={i} onClose={handleClose} closable {...rest} withButton />
              ))}
            </Space>
            {withCalculations && (
              <Heading level={4} className="h-mt-16 h-mb-12">
                Ваши расчёты и заявки
              </Heading>
            )}
          </>
        )}
        <Space size={16} direction="vertical">
          {calculations.map(({ ...rest }, i) => (
            <ActionCard key={i + 10} onClose={handleClose} closable {...rest} withButton={false} />
          ))}
        </Space>
      </>
    );
  }, [orders, calculations, withOrders, withCalculations, handleClose]);

  return (
    <>
      {primaryAction && (
        <ActionCard
          {...primaryAction}
          className={cx('primaryCard')}
          withButton
          closable={isSingleCard}
          link={isSingleCard ? primaryAction.link : undefined}
          onClick={handleClick}
          onClose={handleClose}
          isPrimaryCard
        />
      )}
      {isMobile ? (
        <Sheet visible={visible} onClose={setVisible.off} closeOnBackward>
          <Sheet.Header title={title} />
          <Sheet.Content>{cards}</Sheet.Content>
        </Sheet>
      ) : (
        <Dialog visible={visible} onClose={setVisible.off} className={cx('dialog')} closeOnBackward>
          <Dialog.Header title={title} />
          <Dialog.Content>{cards}</Dialog.Content>
        </Dialog>
      )}
    </>
  );
};
