import { startOfDay, parseISO, format, formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';

import { Car } from '@sravni/react-icons';

import { ACTION_TYPE, PRODUCT_TYPE } from '../../../interfaces/recentAction';
import { IOsagoCalculationData, IOsagoOrderData } from '../types/actionDataTypes';
import { IRecentActionCard } from '../types/recentActionCard';

export const getOsagoCalculation = (action: IOsagoCalculationData): IRecentActionCard => {
  const { meta, payload } = action;

  return {
    actionId: action.actionId,
    cardColor: 'blue',
    hintColor: undefined,
    buttonVariant: 'outlined',
    icon: <Car />,
    hint: 'Полис ОСАГО',
    description: `${payload.mark || ''} ${payload.model || ''}`,
    buttonText: 'Вернуться',
    link: `/osago/?calculationHash=${meta.searchId}`,
    product: `${PRODUCT_TYPE.OSAGO}-${ACTION_TYPE.SEARCH_RESULTS_V2}`,
  };
};

export const getOsagoOrder = (action: IOsagoOrderData): IRecentActionCard => {
  const { meta, payload } = action;
  const dateEnd = parseISO(payload.dateEnd);

  return {
    actionId: action.actionId,
    cardColor: 'red',
    icon: <Car />,
    hint:
      dateEnd >= startOfDay(new Date())
        ? `Заканчивается ${format(dateEnd, 'd MMMM', { locale: ru })}`
        : `Закончился ${formatDistanceToNow(dateEnd, { locale: ru })} назад`,
    hintColor: 'red',
    description: `${payload.mark || ''} ${payload.model || ''}`,
    buttonVariant: 'primary',
    buttonText: 'Продлить',
    link: `/osago/?orderHash=${meta.externalId}`,
    product: `${PRODUCT_TYPE.OSAGO}-${ACTION_TYPE.ORDER_V2}`,
  };
};
