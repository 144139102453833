import { pluralizeByUnit } from '@sravni/utils/lib/pluralize';

export const makePeriodName = (period: number): string => {
  if (period < 12) {
    const pluralizePeriod = pluralizeByUnit(period, 'month');

    return `На ${period} ${pluralizePeriod}`;
  }

  const yearPeriod = period / 12;
  const pluralizePeriod = pluralizeByUnit(yearPeriod, 'year');

  return `На ${yearPeriod} ${pluralizePeriod}`;
};
