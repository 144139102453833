import { useMortgages } from '../../../../hooks/mortgages';
import { Mortgages } from '../../../../interfaces/mortgages';
import { PROPOSITION_LINK, PROPOSITION_TYPE } from '../../constants/proposition';
import { IPropositions } from '../../types/propositionBlock';
import { PropositionCard } from '../PropositionCard';

import styles from './MortgageCard.module.scss';

const prepareMortgageTopData = (mortgageTopData: Mortgages): IPropositions => {
  return mortgageTopData?.map(({ programName, rate, url }) => {
    return { title: programName, rateTitle: rate ? `${rate}%` : null, href: url };
  });
};

export const MortgageCard = () => {
  const mortgages = useMortgages();

  return (
    <PropositionCard
      type={PROPOSITION_TYPE.MORTGAGE}
      title="Ставки по ипотеке"
      subTitle="Проверили ставки в 59 банках"
      link={PROPOSITION_LINK.MORTGAGE}
      data={prepareMortgageTopData(mortgages)}
      className={styles.mortgage}
    />
  );
};
