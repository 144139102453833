import React, { useEffect, useState } from 'react';

import { Card, Space, Typography } from '@sravni/react-design-system';
import { useStyles } from '@sravni/react-utils';

import { CUR_FETCH_PARAMS, CURRENCIES_DATA } from '../../constants/currencies';
import { decorateCurrencies } from '../../helpers/currencies';
import { getCbrfRates } from '../../services/currencies';

import styles from './currencies.module.scss';
import CurrencyItem from './item';

export const Currencies = React.memo(() => {
  const [curRates, setCurrencies] = useState([]);
  const cx = useStyles(styles);

  const fetchCurrencies = async () => {
    let res;

    try {
      res = await getCbrfRates(CUR_FETCH_PARAMS);
    } catch (error) {
      res = [];
    }

    setCurrencies(res);
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const rates = decorateCurrencies(curRates, {});

  return (
    <Card color="dark" size={16} className={cx('container')}>
      <Space inline>
        {CURRENCIES_DATA.map((item) => (
          <CurrencyItem currency={rates && rates[item.code]} item={item} key={item.code} />
        ))}
      </Space>
      <Typography.Link href="/valjuty/">Все курсы</Typography.Link>
    </Card>
  );
});
