import { Badge } from '@sravni/react-design-system';
import { TimeDotted, DocumentEdit } from '@sravni/react-icons';

import { IRecentActionCard, IRecentActions } from '../types/recentActionCard';

export const getPrimaryAction = (data: IRecentActions): IRecentActionCard => {
  const ordersLength = data.orders.length;
  const calculationsLength = data.calculations.length;
  if (ordersLength + calculationsLength <= 1) {
    return data.orders[0] || data.calculations[0];
  }

  return !!ordersLength
    ? {
        cardColor: 'red',
        icon: <TimeDotted />,
        hint: 'Расчеты и заявки',
        hintColor: 'red',
        description: 'У вас есть полисы к продлению',
        buttonText: (
          <>
            Посмотреть <Badge text={ordersLength + calculationsLength} shape="circle" color="red" variant="primary" />
          </>
        ),
        buttonVariant: 'primary',
        link: '',
      }
    : {
        cardColor: 'blue',
        icon: <DocumentEdit />,
        hint: 'Вернуться к расчетам',
        hintColor: undefined,
        description: 'Сохранили ваши расчеты и заявки',
        buttonText: (
          <>
            Показать <Badge text={ordersLength + calculationsLength} shape="circle" color="red" variant="primary" />
          </>
        ),
        buttonVariant: 'outlined',
        link: '',
      };
};
