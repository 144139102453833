import { Wallet } from '@sravni/react-icons';

import { ACTION_TYPE, PRODUCT_TYPE } from '../../../interfaces/recentAction';
import { ICreditCalculationData } from '../types/actionDataTypes';
import { IRecentActionCard } from '../types/recentActionCard';

import { addThousandSpaces } from './changeData';

export const getCreditCalculation = (action: ICreditCalculationData): IRecentActionCard => {
  const { meta, payload } = action;
  const {
    filters: { amount },
  } = meta;
  const amountString = amount ? `на ${addThousandSpaces(amount)} ₽` : '';
  const products = payload.products?.length;

  return {
    actionId: action.actionId,
    cardColor: 'blue',
    buttonVariant: 'outlined',
    icon: <Wallet />,
    hint: 'Продолжить заполнение',
    hintColor: products ? 'blue' : undefined,
    description: `Кредит ${amountString}`,
    buttonText: 'Продолжить',
    link: `/kredity/onlajn-zayavka-na-kredit/`,
    product: `${PRODUCT_TYPE.CREDIT}-${ACTION_TYPE.SEARCH_RESULTS_V2}`,
  };
};
