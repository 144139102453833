import { Plane } from '@sravni/react-icons';

import { ACTION_TYPE, PRODUCT_TYPE } from '../../../interfaces/recentAction';
import { IVZRSearch } from '../types/actionDataTypes';
import { IRecentActionCard } from '../types/recentActionCard';

export const getVZRData = (action: IVZRSearch): IRecentActionCard => {
  const { meta } = action;

  return {
    actionId: action.actionId,
    cardColor: 'blue',
    hintColor: undefined,
    buttonVariant: 'outlined',
    hint: 'Продолжить покупку',
    icon: <Plane />,
    description: `Полис туриста`,
    buttonText: 'Продолжить',
    link: `${meta?.url}`,
    product: `${PRODUCT_TYPE.VZR}-${ACTION_TYPE.SEARCH_RESULTS_V2}`,
  };
};
