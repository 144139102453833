import React from 'react';

import { Layout } from '@sravni/react-design-system';
import { useStyles } from '@sravni/react-utils';

import { LayoutWrapper } from '../LayoutWrapper';

import { DepositsCard } from './components/DepositsCard';
import { MortgageCard } from './components/MortgageCard';
import { MortgageInsuranceCard } from './components/MortgageInsuranceCard';
import styles from './PropositionBlock.module.scss';

export const PropositionBlock: React.FC = () => {
  const cx = useStyles(styles);

  return (
    <LayoutWrapper topBorders={false} bottomBorders={false}>
      <Layout className={cx('h-pt-40', 'h-pb-40')}>
        <div className={cx('container')}>
          <DepositsCard />
          <MortgageCard />
          <MortgageInsuranceCard />
        </div>
      </Layout>
    </LayoutWrapper>
  );
};
