import { startOfDay, parseISO, format, formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';

import { House } from '@sravni/react-icons';

import { ACTION_TYPE, PRODUCT_TYPE } from '../../../interfaces/recentAction';
import { mortgageInsuranceTypeMap } from '../constants';
import { IMortgageCalculationData, IMortgageOrderData } from '../types/actionDataTypes';
import { IRecentActionCard } from '../types/recentActionCard';

export const getsMortgageCalculation = (action: IMortgageCalculationData): IRecentActionCard => {
  const { meta } = action;
  const mortgageType = meta.filters;

  return {
    actionId: action.actionId,
    cardColor: 'blue',
    hintColor: undefined,
    buttonVariant: 'outlined',
    hint: mortgageType ? 'Полис для ипотеки' : 'Продолжить покупку',
    description: mortgageType ? mortgageInsuranceTypeMap[meta.filters.type] : 'Полис для ипотеки',
    buttonText: 'Продолжить',
    link: `/strahovanie-ipoteki/propositions/?searchId=${meta.searchId}`,
    product: `${PRODUCT_TYPE.MORTGAGE}-${ACTION_TYPE.SEARCH_RESULTS_V2}`,
    icon: <House />,
  };
};

export const getMortgageOrder = (action: IMortgageOrderData): IRecentActionCard => {
  const { meta, payload } = action;
  const dateEnd = parseISO(payload.dateEnd);

  return {
    actionId: action.actionId,
    cardColor: 'red',
    icon: <House />,
    hint:
      dateEnd >= startOfDay(new Date())
        ? `Заканчивается ${format(dateEnd, 'd MMMM', { locale: ru })}`
        : `Закончился ${formatDistanceToNow(dateEnd, { locale: ru })} назад`,
    hintColor: 'red',
    description: 'Полис для ипотеки',
    buttonVariant: 'primary',
    buttonText: 'Продлить',
    link: `/strahovanie-ipoteki/propositions/${meta.externalId}/?&source_type=yourActions`,
    product: `${PRODUCT_TYPE.MORTGAGE}-${ACTION_TYPE.ORDER_V2}`,
  };
};
