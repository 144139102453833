type TravelLinks = {
  title: string;
  href: string;
  eventLabel: string;
};

export const travelLinks: TravelLinks[] = [
  { title: 'Авиабилеты', href: '/booking-avia/', eventLabel: 'Купить авиа билеты' },
  { title: 'Ж/д билеты', href: '/booking-rw/', eventLabel: 'Купить жд билеты' },
  { title: 'Отели', href: '/booking-hotels/', eventLabel: 'Забронировать отель' },
];
